<template>
  <vf-notification v-if="success" type="success" :dismissible="false" class="mb-4">
    {{ $t.forgotPasswordSuccessMessage }}
  </vf-notification>
  <div data-test-id="form-reset-password-request">
    <h2 :class="brandClasses.heading">
      {{ $t.forgotPasswordTitle }}
    </h2>
    <base-form :form="form" validate-on="submit" :class="brandClasses.form" @submit="submit">
      <vf-form-field
        v-slot="{ invalid }"
        name="username"
        :rule="[validateRequired($t.email), validateEmail()]"
        :hint="$t.emailFormatHint"
      >
        <vf-input v-model="form.username" type="email" :invalid="invalid" required>
          {{ $t.email }}
        </vf-input>
      </vf-form-field>
      <vf-button type="submit" :size="buttonSize" :loading :class="brandClasses.submitButton">
        {{ $t.resetPassword }}
      </vf-button>
    </base-form>
  </div>
</template>

<script lang="ts" setup>
const { email = '' } = defineProps<{
  email?: string
}>()

const { brandClasses, buttonSize } = useAppConfig().components.form.forgotPassword
const { validateEmail, validateRequired } = useLocalizedValidators()
const recaptcha = useRecaptcha()

const form = reactive({
  username: email
})
const loading = ref(false)
const success = ref(false)

async function submit() {
  loading.value = true
  const captchaResponse = await recaptcha.execute('resetPassword')
  const { username } = form

  await useApi().authentication.$resetPassword({
    username,
    recaptcha_response: captchaResponse
  })

  loading.value = false
  success.value = true
  form.username = ''
}
</script>
